.topic-content table {
  page-break-after: auto;
}

.topic-content tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

.topic-content td {
  page-break-inside: avoid;
  page-break-after: auto;
}

.topic-content thead {
  display: table-header-group;
}

.topic-content tfoot {
  display: table-footer-group;
}

h1.row-space-1 {
  color: black !important;
  font-size: 17pt;
}

.footer-channel {
  display: none;
}

.panel.in .panel-chevron:before {
  display: none;
}

.topic-content table {
  width: auto;
  max-width: 100% !important;
}

div#attachements {
  display: none;
}

em {
  font-style: normal !important;
}

p {
  font-size: 12px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
}

.collapse {
  display: block !important;
  height: auto !important;
  visibility: visible !important;
 }

.topic-content > p:first-child:first-letter {
  font-size: 39px !important;
  line-height: 9px !important;
  color: #373737 !important;
  float: left !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin-right: 8px !important;
}

.print-contact {
  margin-left: 0px !important;
  width: 100% !important;
}

img {
  max-width: 200px !important;
  max-height: auto !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.avatar {
  display: none !important;
}

p.title.row-space0,
p.info.row-space-0 {
  display: inline !important;
}

.row.thumbnail.grid {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 0px !important;
}

ul li strong,
ul li strong a {
  font-size: 12px !important;
}
iframe,
#player {
  display: none;
}
/*.thumbnail {
  max-width: 350px !important;
  max-height: auto !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}*/

.timeline li .title, .timeline li .title .text-muted {
    font-size: 12pt !important;
    color: #bd1a1f !important;
}

.sidenav {
  display: none;
}

.timeline li .content {
  border-bottom: 1px solid #eee;
}

.label-contact.clearfix {
  margin-top: 20px;
  margin-bottom: 20px;
}

#menu {
  display: none;
}

.timeline li .title .date {
  display: block;
}

.dataTables_filter {
  display: none;
}

.label-default {
  border: 1px solid #D6D6D6 !important;
  background-color: red;
}

#activities-array_paginate {
  display: none;
}

/*h1, h2, h3, h4, h5, h6 {
  color: #bd1a1f !important;
}*/

img.hover {
  display: none;
}

div.image-print{
  display: block;
  margin-left: 200px;
  margin-right: auto;
  max-width: 200px;
  max-height: 150px;
  width: auto;
  height: auto;
}​

.label-contact {
  margin-bottom: 50px;
}

.page-header .back {
  display: none !important;
}

h1 {
  font-size: 22px;
  padding-top: 0px;
  margin-top: 0px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4, h5, h6, .title {
  font-size: 14px !important;
}

a[href]:after {
  display: none;
}

.btn.btn-xs, .btn.btn-xs:hover {
  display: none;
}

.header,
.footer,
.ads {
  display: none !important;
}

.btn {
  display: none;
}
